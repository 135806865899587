import $ from 'jquery';

const Filter = (() => {
  let options = {
    selector: '',
    parameter: 'state',
  };
  let timeout = null;
  let $element = null;
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      $element = $(options.selector);
      if ($element.length > 0) {
        const defaultValue = $(`${options.selector}:checked`).val();
        $element.change(() => {
          clearTimeout(timeout);
          const value = $(`${options.selector}:checked`).val();
          if (defaultValue !== value) {
            timeout = setTimeout(() => {
              window.location.search = `?${options.parameter}=${value}`;
            }, 750);
          }
        });
      }
    },
    getValue() {
      if ($element !== null && $element.length > 0) {
        const value = $(`${options.selector}:checked:first`).val();
        if (typeof value !== 'undefined') {
          return value;
        }
      }
      return null;
    },
  };
})();

export default Filter;
