import axios from 'axios';
import Cache from 'cache/index';
import Variables from './Variables';

const API = (() => {
    const mockResponse = {
        success: true,
        data: [{}, {}, {}],
    };
    let cacheReports = null;
    let options = {
        baseUrl: '',
    };

    const fetch = function(method, url, params, data, callbackFn) {
        axios({
            method,
            url,
            params,
            data,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        }).then((response) => {
            callbackFn({
                success: API.isValidResponse(response),
                data: response.data,
            });
        }).catch(() => {
            callbackFn({
                success: false,
            });
        });
    };

    return {
        initialize(settings) {
            options = Object.assign(options, settings);
            cacheReports = new Cache(1000 * 60 * 30); // 30 Minutes
        },
        getReport(callbackFn, id) {
            const cached = cacheReports.get(id);
            if (cached !== null) {
                callbackFn({
                    success: true,
                    data: cached,
                });
                return;
            }
            let url = `${options.baseUrl}api/Report`;
            if (Variables.environment !== 'production') {
                url = `${options.baseUrl}responses/Report.json`;
            }
            fetch('GET', url, { id }, null, (response) => {
                if (response.success) {
                    cacheReports.put(response.data.id.toString(), response.data);
                }
                callbackFn({
                    success: true,
                    data: response.data,
                });
            });
        },
        getReportMarkers(callbackFn, search, state) {
            let url = `${options.baseUrl}api/Report/markers`;
            if (Variables.environment !== 'production') {
                url = `${options.baseUrl}responses/ReportMarkers.json`;
            }
            fetch('GET', url, { search, state }, null, (response) => {
                callbackFn(response);
            });
        },
        getReports(callbackFn, offset, limit, search, state, company) {
            let url = `${options.baseUrl}api/Report/list`;
            if (Variables.environment !== 'production') {
                url = `${options.baseUrl}responses/Reports.json`;
            }
            console.log({ callbackFn }, { offset }, { limit }, { search }, { state }, { company });
            fetch('GET', url, {
                search,
                state,
                offset,
                limit,
                company,
            }, null, (response) => {
                callbackFn(response);
            });
        },
        setReportMeta(callbackFn, id, support, follow) {
            const url = `${options.baseUrl}api/Report/meta`;
            if (Variables.environment !== 'production') {
                callbackFn(mockResponse);
                return;
            }
            fetch('POST', url, null, { id, support, follow }, (response) => {
                callbackFn(response);
            });
        },
        getMinds(callbackFn, offset, limit, search, state) {
            const url = `${options.baseUrl}api/MindMaker/list`;
            if (Variables.environment !== 'production') {
                callbackFn(mockResponse);
                return;
            }
            fetch('GET', url, {
                search,
                state,
                offset,
                limit,
            }, null, (response) => {
                callbackFn(response);
            });
        },
        getMindVotes(callbackFn, offset, limit, state) {
            const url = `${options.baseUrl}api/MindMaker/vote_list`;
            if (Variables.environment !== 'production') {
                callbackFn(mockResponse);
                return;
            }
            fetch('GET', url, { state, offset, limit }, null, (response) => {
                callbackFn(response);
            });
        },
        getIdeas(callbackFn, offset, limit, search, state) {
            const url = `${options.baseUrl}api/IdeaScout/list`;
            if (Variables.environment !== 'production') {
                callbackFn(mockResponse);
                return;
            }
            fetch('GET', url, {
                search,
                state,
                offset,
                limit,
            }, null, (response) => {
                callbackFn(response);
            });
        },
        getIdeaParticipants(callbackFn, id, offset, limit) {
            const url = `${options.baseUrl}api/IdeaScout/participant_list`;
            if (Variables.environment !== 'production') {
                callbackFn(mockResponse);
                return;
            }
            fetch('GET', url, {
                id,
                offset,
                limit,
                message: true,
            }, null, (response) => {
                callbackFn(response);
            });
        },
        isValidResponse(response) {
            return response !== null && response.status >= 200 && response.status <= 299;
        },
        isValidErrorResponse(error) {
            return error !== null && typeof error.response !== 'undefined' && error.response !== null;
        },
    };
})();

export default API;