import $ from 'jquery';
import Utility from './../../_assets/scripts/App/Utility';

const GoogleMap = (() => {
  let options = {
    selector: '.google-map',
  };
  const markers = [];
  const maps = {
    default: [],
  };
  const callbacks = [];
  let googleLoaded = false;
  const styles = {
    grayscale: [{
      stylers: [{
        saturation: -100,
      }],
    }],
  };
  const initializeMap = function ($element) {
    if ($element.hasClass('loaded')) {
      return;
    }
    const latitude = $element.data('map-lat');
    const longitude = $element.data('map-lng');
    let zoom = $element.data('map-zoom');
    let maxZoom = $element.data('map-max-zoom');
    let minZoom = $element.data('map-min-zoom');
    let type = $element.data('map-type');

    let validBounds = false;
    let maxBounds = null;
    const bounds = [
      $element.data('bounds-sw-lat'),
      $element.data('bounds-sw-lng'),
      $element.data('bounds-ne-lat'),
      $element.data('bounds-ne-lng'),
    ];

    for (let i = 0; i < bounds.length; i++) {
      if (typeof bounds[i] !== 'undefined' && bounds[i] !== null && !isNaN(bounds[i])) {
        validBounds = true;
      } else {
        validBounds = false;
        break;
      }
    }

    if (validBounds) {
      maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(parseFloat(bounds[0]), parseFloat(bounds[1])),
        new google.maps.LatLng(parseFloat(bounds[2]), parseFloat(bounds[3])),
      );
    }

    const style = $element.data('map-style');
    const marker = $element.data('map-marker');
    const title = $element.data('map-title');
    const name = $element.data('map-name');
    if (typeof type === 'undefined') {
      type = 'roadmap';
    }
    if (typeof zoom === 'undefined') {
      zoom = 15;
    }
    if (typeof maxZoom === 'undefined') {
      maxZoom = 25;
    }
    if (typeof minZoom === 'undefined') {
      minZoom = 1;
    }
    const settings = {
      zoom: parseInt(zoom, 10),
      minZoom: parseInt(minZoom, 10),
      maxZoom: parseInt(maxZoom, 10),
      scrollwheel: false,
      streetViewControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      navigationControl: true,
      navigationControlOptions: {
        style: google.maps.NavigationControlStyle.SMALL,
      },
      mapTypeId: type,
    };
    if (typeof latitude !== 'undefined' && typeof longitude !== 'undefined') {
      settings.center = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      };
    }
    if (typeof style !== 'undefined') {
      if (style in styles) {
        settings.styles = styles[style];
      }
    }
    const map = new google.maps.Map($element.get(0), settings);
    if (typeof name !== 'undefined' && name !== null) {
      if (!Array.isArray(maps[name])) {
        maps[name] = [];
      }
      maps[name].push(map);
    } else {
      maps.default.push(map);
    }
    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
      const $iframes = $element.find('iframe');
      $iframes.each((index, element) => {
        const $el = $(element);
        const iframeTitle = $el.attr('title');
        if (typeof iframeTitle === 'undefined' || iframeTitle.length === 0) {
          if (typeof title !== 'undefined') {
            $iframes.attr('title', title);
          } else {
            $iframes.attr('title', 'Google Map');
          }
        }
      });
      for (let i = 0, l = callbacks.length; i < l; i++) {
        callbacks[i](map, name);
      }
    });
    if (maxBounds !== null) {
      const maxX = maxBounds.getNorthEast().lng();
      const maxY = maxBounds.getNorthEast().lat();
      const minX = maxBounds.getSouthWest().lng();
      const minY = maxBounds.getSouthWest().lat();
      google.maps.event.addListener(map, 'drag', () => {
        if (maxBounds.contains(map.getCenter())) {
          return;
        }
        const c = map.getCenter();
        let x = c.lng();
        let y = c.lat();
        if (x < minX) {
          x = minX;
        }
        if (x > maxX) {
          x = maxX;
        }
        if (y < minY) {
          y = minY;
        }
        if (y > maxY) {
          y = maxY;
        }
        map.setCenter(new google.maps.LatLng(y, x));
      });
    }
    if (typeof marker !== 'undefined') {
      markers.push(new google.maps.Marker({
        position: map.center,
        icon: marker,
        map,
      }));
    }
    $element.addClass('loaded');
  };

  const onGoogleLoaded = function ($elements) {
    if ($elements.length > 0) {
      const lazyAvailable = Utility.isLazyLoadAvailable();
      let count = $elements.length;
      for (let i = 0; i < $elements.length; i++) {
        const $element = $elements.eq(i);
        if (!$element.hasClass('loaded')) {
          if (
            !lazyAvailable ||
            !$element.hasClass(window.lazySizesConfig.lazyClass) ||
            $element.hasClass(window.lazySizesConfig.loadedClass) ||
            $element.hasClass(window.lazySizesConfig.loadingClass)
          ) {
            initializeMap($element);
            count--;
          }
        }
      }
      if (count > 0 && lazyAvailable) {
        const $document = $(document);
        $document.on('lazybeforeunveil.gm', (e) => {
          const $el = $(e.target);
          if ($elements.has($el) && !$el.hasClass('loaded')) {
            initializeMap($el);
            count--;
            if (count <= 0) {
              $document.off('lazybeforeunveil.gm');
            }
          }
        });
      }
    }
    googleLoaded = true;
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $elements = $$(settings.selector);
      if ($elements.length === 0) {
        return;
      }
      Utility.onGoogleLoaded(() => {
        onGoogleLoaded($elements);
      });
    },
    addStyle(name, style) {
      styles[name] = style;
    },
    getMarkers() {
      return markers;
    },
    registerMarker(marker) {
      if (typeof marker !== 'undefined' && marker !== null) {
        markers.push(marker);
      }
    },
    onInitialized(cb) {
      if (typeof cb !== 'function') {
        return;
      }
      callbacks.push(cb);
      if (googleLoaded) {
        for (const key in maps) {
          for (let i = 0, l = maps[key].length; i < l; i++) {
            for (let j = 0, l2 = callbacks.length; j < l2; j++) {
              callbacks[j](maps[key][i], key);
            }
          }
        }
      }
    },
    getMaps() {
      return maps.default;
    },
    getMapsByName(name) {
      if (name in maps) {
        return maps[name];
      }
      return [];
    },
    triggerResizes() {
      if (googleLoaded) {
        for (let i = 0, l = maps.length; i < l; i++) {
          google.maps.event.trigger(maps[i], 'resize');
        }
      }
    },
    triggerResize(map) {
      if (googleLoaded) {
        google.maps.event.trigger(map, 'resize');
      }
    },
  };
})();

export default GoogleMap;
