import 'jquery';

const getScrollbarWidth = function () {
  try {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);
    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    const widthWithScroll = inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
  } catch (err) {
    return null;
  }
};

const getColumnGutterWidth = function () {
  const $column = $$('div[class^="col-"]:first');
  if ($column.length > 0) {
    const gutterWidthHalf = parseInt($column.css('padding-left'), 10);
    if (!Number.isNaN(gutterWidthHalf) && gutterWidthHalf > 0) {
      return gutterWidthHalf * 2;
    }
  }
  return null;
};

const Variables = (() => ({
  user: null,
  config: null,
  environment: process.env.NODE_ENV,
  baseUrl: process.env.BASE_URL,
  gridGutterWidth: 30,
  gridGutterWidthHalf: 15,
  scrollbarWidth: 0,
  stickyMenuHeight: 55,
  breakpoints: {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
  },
  initialize: () => {
    const columnGutterWidth = getColumnGutterWidth();
    if (columnGutterWidth != null) {
      Variables.gridGutterWidthHalf = columnGutterWidth / 2;
      Variables.gridGutterWidth = columnGutterWidth;
    }
    const scrollbarWidth = getScrollbarWidth();
    if (scrollbarWidth != null) {
      Variables.scrollbarWidth = scrollbarWidth;
    }

    let BASE_URL = process.env.BASE_URL;
    if (typeof window.BASE_URL === 'string') {
      BASE_URL = window.BASE_URL;
    }
    if (BASE_URL[BASE_URL.length - 1] !== '/') {
      BASE_URL += '/';
    }
    Variables.baseUrl = BASE_URL;

    if (typeof window.CONFIG !== 'undefined') {
      Variables.config = window.CONFIG;
    }
  },
}))();

export default Variables;
