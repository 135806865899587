const FileUpload = (() => ({
  image($element, action, max) {
    $element.fileinput({
      dropZoneEnabled: false,
      showUpload: false,
      language: 'de',
      async: false,
      required: false,
      ajax: action,
      allowedFileExtensions: ['jpg', 'jpeg', 'png'],
      resizeImage: true,
      maxImageWidth: 2560,
      maxImageHeight: 1440,
      minFileSize: 32,
      maxFileSize: 1024 * 10,
      maxFileCount: typeof max === 'number' ? max : 3,
      resizePreference: 'width',
      layoutTemplates: {
        main1: '<div class="fake-file-upload {class}">\n' +
        '  {caption}\n' +
        '  {browse}\n' +
        '</div>\n' +
        '{preview}',
        caption: '<input class="fake-text file-caption-name" type="text" aria-label="Datei Name" onkeydown="return false;" onpaste="return false;" disabled>',
        btnBrowse: '<div class="fake-button {css}"{status}>\n' +
        '    <i class="zmdi zmdi-folder-outline" aria-hidden="true"></i>\n' +
        '    <span>Bilder auswählen...</span>\n' +
        '</div>',
        preview: '<div class="images-preview file-preview {class}">\n' +
        '    <div class="file-preview-thumbnails">\n' +
        '    </div>\n' +
        '    <div class="clearfix"></div>' +
        '    <div class="kv-fileinput-error"></div>\n' +
        '</div>',
        footer: '',
      },
    });
  },
}))();

export default FileUpload;
