/* eslint-disable no-use-before-define,no-script-url,no-empty,getter-return */
const Prefetch = (() => {
  const ignorePrefixes = [
    '#',
    'javascript:',
    'geopoint:',
    'bingmaps:',
    'maps:',
    'geo:',
    'market:',
    'sms:',
    'tel:',
    'mailto:',
  ];
  let options = {
    ignorePrefixes: [],
    cancelable: true,
    onlyOnce: true,
    crossOrigin: false,
    viewChangePause: true,
    viewChangeDelay: 125,
    hover: true,
    hoverDelay: 50,
    touch: false,
    touchDelay: 50,
    click: false,
    clickDelay: 50,
    callback: null,
  };
  const urlCache = [];
  let defaultEventArgs = false;
  let callbackEnabled = false;
  let previousPrefetch = null;
  let prefetchLink = null;
  let eventTimeoutActive = false;
  let eventTimeout = null;
  let lastViewChange = null;

  const addEvent = function (target, type, fn, args) {
    if (target.attachEvent) {
      target.attachEvent(`on${type}`, fn);
    } else {
      target.addEventListener(type, fn, args);
    }
  };

  const removeEvent = function (target, type, fn, args) {
    if (target.detachEvent) {
      target.detachEvent(`on${type}`, fn);
    } else {
      target.removeEventListener(type, fn, args);
    }
  };

  const getPassiveEventListenersSupport = function () {
    let passive = false;
    if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
      try {
        const args = { get passive() { passive = true; } };
        addEvent(window, 'testPassiveEventSupport', null, args);
        removeEvent(window, 'testPassiveEventSupport', null, args);
      } catch (e) {}
    }
    return passive;
  };

  const isLinkSameOrigin = function (a) {
    return a.host === window.location.host;
  };

  const isLinkSamePage = function (a) {
    return isLinkSameOrigin(a) && a.pathname === window.location.pathname && a.search === window.location.search;
  };

  const removeHashFromUrl = function (url) {
    const index = url.indexOf('#');
    return (index < 0) ? url : url.substr(0, index);
  };

  const isPrefetchable = function (a) {
    return (options.crossOrigin || isLinkSameOrigin(a)) && !isLinkSamePage(a);
  };

  const buildIgnorePrefixSelector = function (array) {
    let selector = '';
    if (Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        selector += `:not([href^="${array[i]}"])`;
      }
    }
    return selector;
  };

  const createLinkTag = function (url, type) {
    const link = document.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    document.getElementsByTagName('head')[0].appendChild(link);
    return link;
  };

  const getEventArgs = function () {
    let eventArgs = false;
    if (getPassiveEventListenersSupport()) {
      eventArgs = { passive: true, capture: false };
    }
    return eventArgs;
  };

  const getPrefetchableLinks = function () {
    let selector = 'a[href]:not([href=""]):not([download]):not([data-no-prefetch])';
    selector += buildIgnorePrefixSelector(ignorePrefixes);
    selector += buildIgnorePrefixSelector(options.ignorePrefixes);
    return Array.prototype.filter.call(document.querySelectorAll(selector), node => isPrefetchable(node));
  };

  const setPrefetchUrl = function (url, link) {
    url = removeHashFromUrl(url);
    if (url === previousPrefetch) {
      return false;
    }
    if (options.onlyOnce && urlCache.indexOf(url) > -1) {
      return false;
    }
    if (prefetchLink === null) {
      prefetchLink = createLinkTag(url, 'prefetch');
    } else {
      prefetchLink.setAttribute('href', url);
    }
    previousPrefetch = url;
    if (options.onlyOnce) {
      const addToCache = link === null || !link.hasAttribute('data-always-prefetch');
      if (addToCache) {
        urlCache.push(url);
        if (link !== null) {
          setLinkEvents(false, link, defaultEventArgs);
        }
      }
    }
    return true;
  };

  const shouldExecute = function (target) {
    if (target === null) {
      return false;
    }
    if (callbackEnabled) {
      const result = options.callback(target, options);
      if (result !== null) {
        return result;
      }
    }
    return (!options.viewChangePause || Date.now() - lastViewChange >= options.viewChangeDelay);
  };

  const getEventDelay = function (type) {
    let delay = 0;
    switch (type) {
      case 'mouseover':
        delay = options.hoverDelay;
        break;
      case 'mousedown':
        delay = options.clickDelay;
        break;
      case 'touchstart':
        delay = options.touchDelay;
        break;
      default:
        break;
    }
    return delay;
  };

  const getEventTarget = function (e) {
    let target = e.target || e.srcElement;
    while (target && target.nodeName !== 'A') {
      target = target.parentNode;
    }
    return target;
  };

  const executeWithDelay = function (fn, delay) {
    if (delay > 0) {
      clearTimeout(eventTimeout);
      eventTimeout = setTimeout(() => {
        eventTimeoutActive = false;
        fn();
      }, delay);
      eventTimeoutActive = true;
    } else {
      fn();
    }
  };

  const onEventCancelTrigger = function () {
    if (!eventTimeoutActive) {
      return;
    }
    clearTimeout(eventTimeout);
    eventTimeoutActive = false;
    if (options.cancelable) {
      setPrefetchUrl('', null);
    }
  };

  const onEventTrigger = function (event) {
    const target = getEventTarget(event);
    if (!shouldExecute(target)) {
      return;
    }
    executeWithDelay(() => {
      setPrefetchUrl(target.href, target);
    }, getEventDelay(event.type));
  };

  const onViewChangeTrigger = function () {
    onEventCancelTrigger();
    lastViewChange = Date.now();
  };

  const setLinkEvents = function (add, link, eventArgs) {
    const method = add ? addEvent : removeEvent;
    if (options.hover) {
      method(link, 'mouseover', onEventTrigger, eventArgs);
      if (options.hoverDelay > 0) {
        method(link, 'dragstart', onEventCancelTrigger, eventArgs);
        method(link, 'mouseout', onEventCancelTrigger, eventArgs);
      }
    }
    if (options.touch) {
      method(link, 'touchstart', onEventTrigger, eventArgs);
      if (options.touchDelay > 0) {
        method(link, 'touchmove', onEventCancelTrigger, eventArgs);
        method(link, 'touchend', onEventCancelTrigger, eventArgs);
      }
    }
    if (options.click) {
      method(link, 'mousedown', onEventTrigger, eventArgs);
      if (options.clickDelay > 0) {
        if (!(options.hover && options.hoverDelay > 0)) {
          method(link, 'dragstart', onEventCancelTrigger, eventArgs);
        }
        method(link, 'mouseup', onEventCancelTrigger, eventArgs);
      }
    }
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      lastViewChange = Date.now();
      callbackEnabled = typeof options.callback === 'function';
      defaultEventArgs = getEventArgs();
      const links = getPrefetchableLinks();
      for (let i = 0; i < links.length; i++) {
        const link = links[i];
        setLinkEvents(true, link, defaultEventArgs);
      }
      if (options.viewChangePause && options.viewChangeDelay > 0 && links.length > 0) {
        addEvent(window, 'resize', onViewChangeTrigger, defaultEventArgs);
        addEvent(window, 'scroll', onViewChangeTrigger, defaultEventArgs);
        addEvent(window, 'orientationchange', onViewChangeTrigger, defaultEventArgs);
      }
    },
    prefetch(url) {
      let success = false;
      const a = window.document.createElement('a');
      a.setAttribute('href', url);
      if (isPrefetchable(a)) {
        success = setPrefetchUrl(url, null);
      }
      return success;
    },
  };
})();

export default Prefetch;
