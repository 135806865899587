import $ from 'jquery';

const SearchCompany = (() => {
    let options = {
        selector: '',
    };
    let $element = null;
    return {
        initialize(settings) {
            options = Object.assign(options, settings);
            $element = $(options.selector);
        },
        getValue() {
            if ($element !== null) {
                return $(options.selector).val();
            }
            return null;
        },
    };
})();

export default SearchCompany;