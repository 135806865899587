import MarkerClusterer from 'marker-clusterer-plus';
import ReportDetail from './Detail';
import API from './../API';
import GoogleMap from '../../../../_modules/google-map/GoogleMap';
import Filter from './../Filter';
import Search from './../Search';

const ReportMap = (() => {
    let options = {
        selector: 'a[data-modal-report-detail]',
        markerUrl: '',
    };
    let googleMap = null;
    const markers = [];
    const callbacks = [];

    const onMapInitialized = function() {
        const state = Filter.getValue();
        const search = Search.getValue();
        API.getReportMarkers((response) => {
            if (!response.success) {
                console.log('Couldn\'t load report markers');
                console.log(response);
                return;
            }
            for (let i = 0; i < response.data.length; i++) {
                const entry = response.data[i];
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(entry.latitude, entry.longitude),
                    map: googleMap,
                    icon: `${options.markerUrl + entry.contentState.name}.png`,
                });
                google.maps.event.addListener(marker, 'click', () => {
                    ReportDetail.showById(entry.id);
                });
                markers.push(marker);
            }
            new MarkerClusterer(googleMap, markers, {
                imagePath: `${options.markerUrl}cluster/m`,
                maxZoom: 20,
            });
            google.maps.event.addListenerOnce(googleMap, 'idle', () => {
                try {
                    // eslint-disable-next-line global-require
                    const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
                    const oms = new OverlappingMarkerSpiderfier(googleMap, {
                        markersWontMove: true,
                        markersWontHide: true,
                        basicFormatEvents: true,
                        keepSpiderfied: true,
                        nearbyDistance: 50,
                    });
                    for (let i = 0; i < markers.length; i++) {
                        oms.addMarker(markers[i]);
                    }
                } catch (e) {
                    // Ignore
                }
            });
        }, search, state);
    };

    return {
        initialize(settings) {
            options = Object.assign(options, settings);

            const maps = GoogleMap.getMaps('reports');
            googleMap = maps != null && maps.length > 0 ? maps[0] : null;
            if (googleMap === null) {
                GoogleMap.onInitialized((map, name) => {
                    if (googleMap === null && name === 'reports') {
                        googleMap = map;
                        for (let i = 0, l = callbacks.length; i < l; i++) {
                            callbacks[i](googleMap);
                        }
                    }
                });
            }

            this.getMap(() => {
                onMapInitialized();
            });
        },
        getMap(callbackFn) {
            if (googleMap === null) {
                callbacks.push(callbackFn);
            } else {
                callbackFn(googleMap);
            }
        },
    };
})();

export default ReportMap;