import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import Variables from './Variables';


const Utility = (() => {
  let passiveEventListeners = null;
  const googleCallbacks = [];
  return {
    initialize: () => {
      const $html = $$('html');
      const $head = $$('head');
      if ($head.length > 0) {
        $(`<style type="text/css">.scrollbar-offset{padding-right:${Variables.scrollbarWidth}px!important;}</style>`)
          .appendTo($head);
      }
      if (Utility.isInternetExplorer()) {
        $html.addClass('ie');
        objectFitImages(null, {
          watchMQ: false,
        });
      }
      $html.addClass(`lazyload-${Utility.isLazyLoadAvailable() ? 'available' : 'unavailable'}`);

      const googleCheckInterval = setInterval(() => {
        if (Utility.isGoogleLoaded()) {
          for (let i = 0, l = googleCallbacks.length; i < l; i++) {
            googleCallbacks[i]();
          }
          clearInterval(googleCheckInterval);
        }
      }, 100);
    },
    doesUrlExist: (url, callback) => {
      if (typeof callback === 'undefined' || callback === null) {
        return;
      }
      $.ajax({
        type: 'HEAD',
        url,
        success() {
          callback(true);
        },
        error() {
          callback(false);
        },
      });
    },
    onGoogleLoaded(callbackFn) {
      if (this.isGoogleLoaded()) {
        callbackFn();
        return;
      }
      googleCallbacks.push(callbackFn);
    },
    isBrowser: () => typeof navigator.plugins !== 'undefined' && navigator.plugins.length > 0,
    isMaterializeLoaded: () => typeof window.Materialize !== 'undefined',
    isGoogleLoaded: () => typeof window.google !== 'undefined',
    isLazyLoadAvailable: () => typeof window.lazySizesConfig !== 'undefined' && !Utility.isInternetExplorer(),
    cleanSelector: selector => (typeof selector !== 'undefined' ?
      selector.toString()
        .replace(/([:.[])/g, '\\$1')
      : ''
    ),
    getUrlFilename: (url) => {
      if (url) {
        const m = url.toString()
          .match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
          return m[1];
        }
      }
      return '';
    },
    isTouchDevice: () => {
      try {
        document.createEvent('TouchEvent');
        return true;
      } catch (e) {
        return false;
      }
    },
    getJSON: (str) => {
      if (typeof (str) === 'object') {
        return str;
      }
      return JSON.parse(str);
    },
    isJSONString: (str) => {
      try {
        if (typeof (str) === 'object') {
          return true;
        }
        if (typeof (str) === 'string') {
          if (str.length === 0) {
            return false;
          }
        }
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    isInternetExplorer: () =>
      !!navigator.userAgent.match(/Trident/g) ||
      !!navigator.userAgent.match(/MSIE/g) ||
      /* @cc_on!@ */false,
    getCaretPosition: ($element) => {
      let position = 0;
      const element = $element.get(0);
      if (document.selection) {
        element.focus();
        const range = document.selection.createRange();
        range.moveStart('character', -element.value.length);
        position = range.text.length;
      } else if (element.selectionStart || element.selectionStart === 0) {
        position = element.selectionStart;
      }
      return position;
    },
    setCaretPosition: ($element, position) => {
      const element = $element.get(0);
      if (element.setSelectionRange) {
        element.focus();
        element.setSelectionRange(position, position);
      } else if (element.createTextRange) {
        const range = element.createTextRange();
        range.collapse(true);
        range.moveEnd('character', position);
        range.moveStart('character', position);
        range.select();
      }
    },
    replaceAt: (string, replacement, index) => string.substr(0, index)
      + replacement + string.substr(index + replacement.length),
    arrayContains: (arr, obj) => {
      let i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return true;
        }
      }
      return false;
    },
    getUrlParameter(name) {
      const url = window.location.href;
      name = name.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    supportsPassiveEventListeners() {
      if (passiveEventListeners !== null) {
        return passiveEventListeners;
      }
      if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
        try {
          // eslint-disable-next-line getter-return
          const args = { get passive() { passiveEventListeners = true; } };
          this.addEvent(window, 'testPassiveEventSupport', null, args);
          this.removeEvent(window, 'testPassiveEventSupport', null, args);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      return passiveEventListeners;
    },
    addEventOnce(target, type, fn, args) {
      const wrapperFn = () => {
        fn();
        this.removeEvent(target, type, fn, args);
      };
      this.addEvent(target, type, wrapperFn, args);
    },
    addEvent(target, type, fn, args) {
      if (target.attachEvent) {
        target.attachEvent(`on${type}`, fn);
      } else {
        target.addEventListener(type, fn, args);
      }
    },
    removeEvent(target, type, fn, args) {
      if (target.detachEvent) {
        target.detachEvent(`on${type}`, fn);
      } else {
        target.removeEventListener(type, fn, args);
      }
    },
    onDocumentReady(fn) {
      if (typeof fn !== 'function') {
        return;
      }
      this.addEvent(document, 'Neos.PageLoaded', fn, false);
      /*
      switch (document.readyState) {
        case 'loaded':
        case 'complete':
        case 'interactive':
          callback();
          break;
        default:
          this.addEvent(document, 'DOMContentLoaded', callback, false);
          break;
      }
      */
      $(document).ready(fn);
    },
    onWindowLoad(fn) {
      if (typeof fn !== 'function') {
        return;
      }
      this.addEvent(window, 'Neos.PageLoaded', fn, false);
      // this.addEvent(window, 'load', callback, false);
      $(window).on('load', fn);
    },
  };
})();

export default Utility;
