/* eslint-disable max-len */
import Utility from '../App/Utility';

(() => {
  if (!Utility.isBrowser()) {
    return;
  }
  // eslint-disable-next-line function-paren-newline
  console.log(
    '██╗    ██╗███████╗██████╗ ██╗    ██╗███████╗██████╗ ██╗  ██╗\n' +
    '██║    ██║██╔════╝██╔══██╗██║    ██║██╔════╝██╔══██╗██║ ██╔╝\n' +
    '██║ █╗ ██║█████╗  ██████╔╝██║ █╗ ██║█████╗  ██████╔╝█████╔╝ \n' +
    '██║███╗██║██╔══╝  ██╔══██╗██║███╗██║██╔══╝  ██╔══██╗██╔═██╗ \n' +
    '╚███╔███╔╝███████╗██████╔╝╚███╔███╔╝███████╗██║  ██║██║  ██╗\n' +
    ' ╚══╝╚══╝ ╚══════╝╚═════╝  ╚══╝╚══╝ ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝\n' +
    '############################################################\n' +
    '#                                                          #\n' +
    '# Author  : Nikita Bernthaler                              #\n' +
    '# Company : Webwerk Online-Solutions GmbH                  #\n' +
    '# Website : https://www.webwerk.at/                        #\n' +
    '#                                                          #\n' +
    '############################################################\n');
})();
