import $ from 'jquery';
import Cookie from 'js-cookie';
import locale2 from 'locale2';

const CookieConsent = (() => {
  let options = {
    parent: 'body',
    cookieName: 'cookie_consent',
    cookieValue: 'true',
    cookieDurationDays: 30,
    additionalOffset: 0,
    defaultLocale: 'de',
    privacyUrl: null,
    text: {
      message: {
        de: 'Wir verwenden Cookies, um unsere Website für Sie möglichst benutzerfreundlich zu gestalten. Wenn Sie ' +
        'fortfahren, gehen wir davon aus, dass Sie damit einverstanden sind.',
        en: 'We use cookies to ensure we give you the best browsing experience on our website. Find out more on how ' +
        'we use cookies.',
      },
      privacy: {
        de: 'Zur Datenschutzerklärung',
        en: 'No, tell me more',
      },
      accept: {
        de: 'OK',
        en: 'OK',
      },
    },
  };

  const getText = function (object, locale) {
    if (hasOwnProperty.call(object, locale)) {
      return object[locale];
    }
    return object[options.defaultLocale];
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);

      if (options.privacyUrl === null) {
        const $meta = $('head meta[name="privacy"]');
        if ($meta.length > 0) {
          const content = $meta.attr('content');
          if (typeof content === 'string' && content.length > 0) {
            options.privacyUrl = content;
          }
        }
      }

      const cookieExists = typeof Cookie.get(options.cookieName) !== 'undefined';
      if (!cookieExists) {
        let language = locale2;
        if (typeof language === 'string') {
          language = language.toLocaleLowerCase().slice(0, 2);
        }

        const $cookieConsent = $('<div />', {
          id: 'cookie-consent',
        });

        const $buttons = $('<div />', {
          class: 'buttons',
        });

        const $accept = $('<button />', {
          class: 'accept',
          type: 'button',
          text: getText(options.text.accept, language),
        });

        $accept.click((e) => {
          e.preventDefault();
          $cookieConsent.remove();
          Cookie.set(options.cookieName, options.cookieValue, { expires: options.cookieDurationDays });
        });

        $cookieConsent.append($('<div />', {
          class: 'message',
          text: getText(options.text.message, language),
        }));

        if (options.privacyUrl !== null) {
          $buttons.append($('<a />', {
            class: 'more',
            href: options.privacyUrl,
            text: getText(options.text.privacy, language),
          }));
        }

        $buttons.append($accept).appendTo($cookieConsent);
        $cookieConsent.prependTo($$(options.parent));
      }
    },
  };
})();

export default CookieConsent;
