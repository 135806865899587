const ScrollTo = (() => {
  let options = {
    selector: '',
    selectorTarget: '',
  };
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $element = $$(options.selector);
      const $target = $$(options.selectorTarget);
      const $body = $$('html, body');
      if ($element.length && $target.length) {
        $element.click(() => {
          $body.animate({
            scrollTop: $target.offset().top,
          }, 500);
        });
      }
    },
  };
})();

export default ScrollTo;
