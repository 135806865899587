import $ from 'jquery';
import Overlay from './../Overlay';
import HandlebarsLoader from './../HandlebarsLoader';
import Modal from './../Modal';
import Variables from '../Variables';

const ReportModal = (() => ({
  initialize(selector, file, fetchDataFn, callbackFn) {
    $('body').on('click', selector, (e) => {
      e.preventDefault();
      this.show(file, fetchDataFn, callbackFn, $(e.currentTarget));
    });
  },
  show(file, fetchDataFn, callbackFn, $trigger = null) {
    Overlay
      .reset()
      .setText('Daten werden geladen...')
      .showLoader(true)
      .show(true);
    HandlebarsLoader.get(file, (template) => {
      if (template === null) {
        callbackFn(false);
        Overlay.reset();
        return;
      }
      const onDataFetched = function (data) {
        if (typeof data !== 'object') {
          callbackFn(false);
          Overlay.reset();
          return;
        }
        const html = template(Object.assign(data, Variables.config));
        const $modal = $($.parseHTML(html)).eq(0);

        Modal.showDynamic($modal);

        callbackFn(true, $modal, data);
        Overlay.reset();
      };

      if (typeof $trigger !== 'undefined' && $trigger !== null) {
        fetchDataFn($trigger, (data) => {
          onDataFetched(data);
        });
      } else {
        fetchDataFn((data) => {
          onDataFetched(data);
        });
      }
    });
  },
}))();

export default ReportModal;
