import './Utility/Polyfill';
import App from './App';
// import Variables from './App/Variables';

window.App = App;

/*
if ('serviceWorker' in navigator) {
  let serviceUrl = `${Variables.baseUrl}assets/template/scripts/`;
  if (Variables.environment !== 'production') {
    serviceUrl = `${Variables.baseUrl}assets/scripts/`;
  }
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`${serviceUrl}service-worker.js`);
  });
}
*/

App.initialize();
