/* eslint-disable no-continue,default-case */
import $ from 'jquery';
import Utility from '../App/Utility';

const GoogleComplete = (() => {
  let options = {
    selector: '#overlay',
  };
  const callbacks = [];
  const autocompletes = [];
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };
  let $elements;

  const getCurrentPosition = function (position) {
    const bounds = new window.google.maps.Circle({
      center: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
      radius: position.coords.accuracy,
    }).getBounds();
    for (let i = 0; i < autocompletes.length; i++) {
      autocompletes[i].setBounds(bounds);
    }
  };

  const fillFields = function () {
    for (let i = 0; i < autocompletes.length; i++) {
      const $el = $elements.eq(i);
      if ($el.length) {
        const place = autocompletes[i].getPlace();
        if (typeof place === 'undefined' || !place.geometry) {
          continue;
        }
        const formattedAddress = place.formatted_address;
        let postalCode;
        let locality;
        let streetNumber;
        let route;
        for (let j = 0; j < place.address_components.length; j++) {
          const addressType = place.address_components[j].types[0];
          const val = place.address_components[j][componentForm[addressType]];
          switch (addressType) {
            case 'postal_code':
              postalCode = val;
              break;
            case 'locality':
              locality = val;
              break;
            case 'street_number':
              streetNumber = val;
              break;
            case 'route':
              route = val;
              break;
          }
        }
        const dataPostalCode = $el.data('google-postal_code');
        const dataLocality = $el.data('google-locality');
        const dataStreetNumber = $el.data('google-street_number');
        const dataRoute = $el.data('google-route');
        if (dataPostalCode) {
          $(`input[name=${Utility.cleanSelector(dataPostalCode)}]`).val(postalCode || '').change();
        }
        if (dataLocality) {
          $(`input[name=${Utility.cleanSelector(dataLocality)}]`).val(locality || '').change();
        }
        if (dataStreetNumber) {
          $(`input[name=${Utility.cleanSelector(dataStreetNumber)}]`).val(streetNumber || '').change();
        }
        if (dataRoute && route) {
          $(`input[name=${Utility.cleanSelector(dataRoute)}]`).val(route).change();
        }

        const args = {
          city: locality,
          zipCode: postalCode,
          street: route,
          streetNumber,
          formattedAddress,
        };
        for (let j = 0, l = callbacks.length; j < l; j++) {
          callbacks[j]($el, place.geometry, args);
        }
      }
    }
  };

  return {
    geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getCurrentPosition);
      }
    },
    onAddressChanged(cb) {
      if (typeof cb !== 'function') {
        return;
      }
      callbacks.push(cb);
    },
    initialize(settings) {
      options = Object.assign(options, settings);
      Utility.onGoogleLoaded(() => {
        $elements = $(settings.selector);
        $elements.each((index, element) => {
          const $element = $(element);
          let lastValue = $element.val();
          const autocomplete = new window.google.maps.places.Autocomplete(element, {
            types: ['geocode'],
            componentRestrictions: { country: 'at' },
          });
          autocomplete.addListener('place_changed', fillFields);
          autocompletes.push(autocomplete);
          $element.focus(() => {
            GoogleComplete.geolocate();
          });
          $element.focusout(() => {
            const currentValue = $element.val();
            if (currentValue !== lastValue) {
              lastValue = currentValue;
              google.maps.event.trigger(autocomplete, 'place_changed');
            }
          }).blur(() => {
            const currentValue = $element.val();
            if (currentValue !== lastValue) {
              lastValue = currentValue;
              google.maps.event.trigger(autocomplete, 'place_changed');
            }
          });
        });
      });
    },
  };
})();

module.exports = GoogleComplete;
