import $ from 'jquery';
import Modal from './Modal';

const ImageModal = (() => {
  let options = {
    selector: '',
  };
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      $$('body').on('click', options.selector, function (e) {
        e.preventDefault();
        ImageModal.show($(this));
      });
    },
    show($element) {
      const originalSource = $element.data('image-original');
      const $img = $element.find('img:first');
      if (typeof originalSource !== 'undefined' && originalSource !== null) {
        let text = null;
        if ($img.length > 0) {
          text = $img.eq(0).attr('alt');
        }
        Modal.showHandlebar('modal/image.hbs', {
          title: text,
          alternative_text: text,
          src: originalSource,
        });
      }
    },
  };
})();

export default ImageModal;
